.list_of_client{
    color:black;
    font-weight:700;
    font-size: 1.1rem;
    margin-bottom: 5px;
}

.pagination {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination li a {
  padding: 5px 10px;
  border: 1px solid #ddd;
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
}

.pagination li.active a {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.pagination li.disabled a {
  color: #ccc;
  cursor: not-allowed;
}
.validation-text{
  color:red;
}



.password-input-container {
  position: relative;

}

.eyes-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px; /* Adjust this value to your preference */
  background: transparent;
  border: none;
  cursor: pointer;
}

/* .search_part {
  padding-left: 35px; 
} */

.password-input-container .eye-button {
  display: none;
}

.password-input-container input[type="password"]::-ms-reveal,
.password-input-container input[type="password"]::-ms-clear {
  display: none;
}

.password-input-container input[type="password"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}