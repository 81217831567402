@import "../../abstracts/variable";
@import "../../abstracts/mixin";
[data-sidebar-style="full"][data-layout="vertical"] {
    .dlabnav{
		
		.metismenu{

			& > li{
				padding: 0 1.25rem 0 0;
				& > a{
					// font-size: 1.125rem;
					font-size: 1rem;
					// padding: 1rem 2.1875rem;
					padding: 0.750rem 2rem;
					font-weight:400;
					@include transitionMedium;
					&:before{
						position:absolute;
						content:"";
						background:var(--primary);
						width:0;
						height:100%;
						top:0;
						left:0rem;
						border-top-right-radius: 3.563rem;
						border-bottom-right-radius: 3.563rem;
						@include transitionMedium;
						
					}
					
				}
				&.mm-active{

					& > a{
						font-weight:600;
						background: var(--rgba-primary-1);
						// border-bottom-right-radius: 5rem;
						border-bottom-right-radius: 2.5rem;
						// border-top-right-radius: 0.9rem;
						border-top-right-radius: 0.5rem;
						 &:before{
							width:0.563rem;
							@include respond ('laptop'){
								width:0.600rem;	
							}
						}
					
						i{
							color: var(--primary);
							font-weight: 900;
						}
					}
				}
				.has-arrow:after{
					right: 3.5rem;
				}
				@include respond ('laptop') {
					padding:0 0;	
					& > a{
						font-size: 1rem;
						padding: 0.9375rem 1.25rem;
					}
				}
			}
			
		}
		
	}
	.menu-toggle {
        .nav-header {
            width: 6.5rem;
            z-index: 999;
            .brand-logo {
                padding-left: 0;
                padding-right: 0;
                justify-content: center;
            }
            .nav-control {
				right: -5rem;
                .hamburger {
                    .line {
                        background-color: var(--primary);
                    }
                }
            }
        }
		.copyright,
		.plus-box{
			display:none;
		}
        .header {
            padding-left: 7.5rem;
            width: 100%;
            @at-root [direction="rtl"]#{&} {
                padding: 0 0.9375rem;
                padding-right: 7.5rem;
            }
			.header-content{
				padding-left:6rem;
			}
        }
        .dlabnav {
            width: 6.5rem;
            overflow: visible;
            position: absolute;
            .nav-text {
                display: none;
            }
            .slimScrollDiv,
            .dlabnav-scroll {
                overflow: visible !important;
            }
			.header-info2{
				padding:0;	
				img{
					margin-left:-1rem;	
				}
			}
			.sidebar-info{
				display:none!important;	
			}
			.header-profile2{
				margin: 0 0.5rem;	
			}
            .metismenu {
			
				
                li {
                    position: relative;
					
                    a {
						background:transparent;
						margin: 0.125rem 0;
						
						svg{
						    max-width: 1.5rem;
							max-height: 1.5rem;
							margin-right: 0;
						}
						&:before{
							content:none;
						}
						i{
							margin: 0;
						}
                    }
                    &>ul {
                        position: absolute;
						left: 5.8rem;
						top: 0;
						width: 12rem;
						z-index: 1001;
						display: none;
						padding-left: 0.0625rem;
						height: auto !important;
						box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
						border-radius: 0.375rem;
						margin-left: 0;
						border:0;
						background:$white;

                        @at-root [direction="rtl"]#{&} {
                            left: auto;
                            right: 5rem;
                            // box-shadow: -0.375rem 0.375rem 0.625rem rgba(0, 0, 0, 0.15);
                        }
                        li:hover {
                            ul {
                                // display: block;
                                left: 11.8125rem;
                                top: 0;
								&:after{
									content:none;
								}
                            }
                        }
                    }
                    &:hover>ul {
                        display: block;
                        height: auto;
                        overflow: visible;
                    }
                }
                &>li {
                    transition: all 0.4s ease-in-out;
					padding: 0 1.125rem;
					
                    &>a {
						padding: 0.75rem 0.225rem;
						text-align: center;
						// border-radius: 3rem;
						height: 48px;
						width: 48px;
                        &.has-arrow {
                            &:after {
                                display: none;
                            }
                        }
                    }
                    &.mm-active > a{
						background:var(--primary);
						// border-radius:3rem;
						border-radius:$radius;
						i{
							color:$white;
							padding:0;
						}
					}
                    &:hover{
                        &:nth-last-child(-n + 1) {
                            &>ul {
                                bottom: 0;
                                top: auto;
                            }
                        }
                        &>a {
							// border-radius: 3rem;
							border-radius: $radius;
							background:var(--primary);
							color:var(--primary);
							@at-root [data-theme-version="dark"]#{&} {
								background:$dark-card;
							}
							i{
								color:$white;
								/* background:$primary; */
							}
                        }
                        &>ul {
                            height: auto !important;
							padding: 0.625rem 0;						
							
                            a {
                                padding: 0.375rem 1.25rem 0.375rem 1.25rem;
                                margin-left: -.1rem;
                            }
                            ul{
								padding: 0.625rem 0;
								a {
									padding: 0.375rem 1.25rem 0.375rem 1.25rem;
									margin-left: -.1rem;
								}
                            }
                        }
                    }
                }
                .nav-label,
                .nav-badge {
                    display: none;
					
                }
            }
			.side-bar-profile{
				display:none;	
			}
        }
        .content-body {
            margin-left: 6.5rem;
            @at-root [direction="rtl"]#{&} {
                margin-right: 5.7rem;
                margin-left: auto;
				border: 0;
            }
        }
        &+.footer {
            padding-left: 5.7rem;
            @at-root [direction="rtl"]#{&} {
                padding-left: 0;
                padding-right: 5.7rem;
            }
        }
		
    }
}
[data-sidebar-style="full"][data-layout="horizontal"] {
	.header .header-content{
		padding-left: 1.875rem;
	}
	.nav-header {
		width: 17rem;
		.brand-logo {
			padding-left: 1.5rem;
			padding-right: 1rem;
		}
	}
}
